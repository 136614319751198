import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useAuthStore } from '@/js/store/auth/AuthStore';
import { useDeviceSize } from '@/js/composables/useDeviceSize';
import { OWNER_ROLE } from '@/js/constants/generalConstants';

export const useDashboardBarStore = defineStore('MenuStore', () => {
    const authStore = useAuthStore();
    const { isMobileOrTablet } = useDeviceSize();

    const isAllowedPage = ref(false);

    const isAdmin = computed(() => authStore.isAdmin());
    const isStudioUnlocked = computed(() => authStore.user?.role === OWNER_ROLE);
    const hasPermissions = computed(() => isAdmin.value || isStudioUnlocked.value);

    const isBarVisible = computed(() => {
        if (!isAllowedPage.value) {
            return false;
        }

        if (isMobileOrTablet.value) {
            return false;
        }

        return hasPermissions.value;
    });

    const showDashboardBar = () => {
        if (!authStore.isUserLoggedIn || !hasPermissions.value) {
            return;
        }

        isAllowedPage.value = true;
    };
    const hideDashboardBar = () => {
        isAllowedPage.value = false;
    };

    return {
        isBarVisible,
        showDashboardBar,
        hideDashboardBar,
    };
});
